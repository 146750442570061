* {
  box-sizing: border-box;
  /* outline: 1px dashed #f00; */
}
.backgroundColorBlue {
  background-color: #2492eb !important;
}
.colorWhite {
  color: white !important;
}
.colorGreen {
  color: #b6cf56;
}
.colorBlue {
  color: #2492eb !important;
}
body {
  margin: 5em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #eeeeee; */
  background-color: transparent;
}
.flex {
  display: flex;
}
.flex4 {
  flex: 4;
}

.hidden {
  display: none;
}
.ui-dropdown__container {
  width: 100% !important;
}

.callbix-a-link{
  color: #b6cf56;
  text-decoration: none;
}

.textAlignCenter {
  text-align: center;
}

.textAlignEnd {
  text-align: end;
}

.settingsDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.childDivSettings {
  width: 350px;
}

.textAreaSettings {
  width: 350px;
  height: 80px;
}

.connectorDialog {
  width: 350px !important;
  height: 500px;
}

.connectorImage{
  height: 20px;
  width: 20px;
  border-radius: 3px;
  margin-right: 10px;
}

.connectorTable > .ui-table > .ui-table__header :nth-child(1) > .ui-table__cell__content{
  margin-left: 0px;
  display: flex;
}

.connectorTable > .ui-table > .ui-table__cell{
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.callImage{
  height: 20px;
  width: 20px;
  border-radius: 3px;
  margin-right: 10px;
}

.callTable > .ui-table > .ui-table__header :nth-child(1) > .ui-table__cell__content{
  margin-left: 0px;
  display: flex;
}

.callTable > .ui-table > .ui-table__cell{
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.headerLogo {
  width: 80px;
  padding-right: 10px;
}

.headerTextDiv{
  display: flex;
  align-items: center;
  align-content: center;
  flex-flow: wrap;
  height: 115px;
  width: 800px;
  margin-right: 60px;
  padding-left: 0px;
  padding-right: 10px;
  padding-top: 10px;
  margin-right: 30px;
  word-break: break-all;
}

.containerflex{
  /*for desktop*/
  flex-direction: column;
}

.containerLogoConnectors{
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.containerLogoConnectors img{
  width: 80%;
}

@media screen and (min-width: 500px) {
  .childDivSettings {
    width: 500px;
  }

  .textAreaSettings {
    width: 500px;
    height: 60px;
  }

  .connectorDialog {
    width: 645px !important;
    height: 500px;
  }
  
  .connectorImage{
    height: 35px;
    width: 35px;
    border-radius: 3px;
    margin-right: 30px;
  }

  .connectorTable > .ui-table > .ui-table__header :nth-child(1) > .ui-table__cell__content{
    margin-left: 64px;
    display: flex;
  }

  .connectorTable > .ui-table > .ui-table__cell{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .callImage{
    height: 35px;
    width: 35px;
    border-radius: 3px;
    margin-right: 30px;
  }

  .callTable > .ui-table > .ui-table__header :nth-child(1) > .ui-table__cell__content{
    margin-left: 64px;
    display: flex;
  }

  .callTable > .ui-table > .ui-table__cell{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .headerLogo {
    width: auto;
  }

  .headerTextDiv{
    display: flex;
    align-items: center;
    align-content: center;
    flex-flow: wrap;
    height: 115px;
    width: 800px;
    margin-right: 60px;
    padding-left: 60px;
    padding-right: 0px;
    margin-right: 20px;
    word-break: break-all;
  }

  .containerflex{
    /*for desktop*/
    flex-direction: row;
  }

  .containerLogoConnectors img{
    width: auto;
  }
}

.marginTabElements {
  margin-top: 20px;
}

.settingsBtn {
  margin-top: 30px;
  width: 150px;
  color: #ffffff !important;
}

.textWarning {
  color: red;
}

.fieldRequiredDiv {
  width: 500px;
  text-align: end;
}

.headerLogoDiv{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 115px;
  padding-left: 0px;
}

.headerBtnDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 115px;
  margin-right: 10px;
  padding-right: 12px;
}

.connectorTable > .ui-table > .ui-table__row{
  border-bottom: 2px solid #d3d4d6 !important;
  height: 70px !important;
}

.connectorTable > .ui-table > .ui-table__header{
  border-bottom: 2px solid #a3a3a3 !important;
}

.callTable > .ui-table > .ui-table__row{
  border-bottom: 2px solid #d3d4d6 !important;
  height: 50px !important;
}

.callTable > .ui-table > .ui-table__header{
  border-bottom: 2px solid #a3a3a3 !important;
}

.dropdownSettings {
  width: 500px;
  white-space: nowrap; 
  text-overflow: ellipsis;
}

.topBar{
  height: 115px;
  display: flex;
}

.ui-button__content{
  overflow: inherit !important;
}

.text-bold{
  font-weight: bold;
}