input:checked + label > img {
  opacity: 1;
  border: 2px solid #1c8dab;
  border-radius: 4px;
}
input + label > img {
  cursor: pointer;
  border: 2px solid #b3b1ae;
  opacity: 0.3;
  border-radius: 4px;
}
label > input + img {
  /* IMAGE STYLES */
  cursor: pointer;
  border: 2px solid #b3b1ae;
  opacity: 0.3;
}
